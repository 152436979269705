import "./Game.scss";
import React from "react";
import { useInView } from "react-intersection-observer";
import GameIcon from "../../assets/img/game.png";
const Game = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} id="game" className={`Game ComponentSection visible_${inView}`}>
      <div className="VideoContainer">
        <video muted loop className="BgGameVideo" autoPlay>
          <source
            src="https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d6e2347b6080002dc772b6?alt=media&timestamp=1641472565560"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="SectionTitle">
        <img src={GameIcon} className="Icon" />
        <h1>Game</h1>
      </div>
      <div className="Container">
        <div className="Text">
          Whale Islands is the first resource management game on Avalanche. As a merchant,
          you trade in-game goods with islands and grow your business. Every player uses
          $WISL to trade goods and all profits reflects your AVAX wallet immediately.
          <br />
          <br />
          Instead of a complex trading ecosystem, Whale Island offers a stress free
          trading game to its players. Each city has a consumption and production for each
          good. To earn $WISL, you just transfer goods from one city to another.
        </div>
      </div>
    </div>
  );
};

export default Game;
